<template>
    <b-card title="Sales & Delivery">
        <b-row>
            <b-col md="12">
                <b-input-group class="mt-2 mr-2">
                <b-input-group-prepend>
                    <div>
                        <vSelect :options="searchCritriaOption" label="name" v-model="searchCritria"
                            placeholder="Search ">
                        </vSelect>
                    </div>
                </b-input-group-prepend>
                <b-col>
                    <v-select class="w-100" :filterable="false" :placeholder="Selected" :options="customersList"
                        @search="onInputChange" v-model="searchBoxValue" @input="onSelected">
                        <template #option="{ fullName, mobileNo, id }">
                            <h4>{{ fullName }}{{ [id] }}</h4>
                            <span>{{ mobileNo }} </span>
                        </template>
                    </v-select>
                </b-col>
            </b-input-group>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            
            <b-col md="3">
                <b-form-group label="Store Name">
                    <v-select v-model="storeName" label="storeName" :options="storeNameOptions" />
                </b-form-group>
            </b-col>
            <b-col md="3">
                <b-form-group label="Order Status">
                    <v-select v-model="orderStatus" placeholder="Select Order Status" :options="orderStatusOptions" />
                </b-form-group>
            </b-col>
            <!-- <b-col md="3">
                <b-form-group label="Order Created  By">
                    <v-select v-model="orderCreatedBy" placeholder="Select Order Created By" label="name"
                        :options="orderCreatedByOptions" />
                </b-form-group>
            </b-col>
            <b-col md="3">
                <b-form-group label="Order Inspected by">
                    <v-select v-model="orderInspectedBy" placeholder="Select Order Inspected By" label="name"
                        :options="orderInspectedByOptions" />
                </b-form-group>
            </b-col> -->
            <!-- <b-col md="3">
                <b-form-group label="Due Date">
                    <flat-pickr v-model="dueDate" class="form-control" name="date"
                        placeholder="Please Enter Due Date" />
                </b-form-group>
            </b-col> -->
            <b-col md="2">
                <b-form-group label="From Date">
                    <flat-pickr v-model="fromDate" class="form-control" name="date"
                        placeholder="Please Enter From Date" />
                </b-form-group>
            </b-col>
            <b-col md="2">
                <b-form-group label="To Date">
                    <flat-pickr v-model="toDate" class="form-control" name="date" placeholder="Please Enter To Date" />
                </b-form-group>
            </b-col>
            <b-col md="2">
                <b-button block class="mt-2" variant="primary" @click="getOrderFilteredList">Filter</b-button>
            </b-col>
        </b-row>
        <hr>
        <div>
            <!-- search input -->
            <div class="custom-search justify-content-end">
                <b-row>
                    <b-col md="8">
                        <!-- <b-button @click="printReport">Print Report</b-button> -->
                    </b-col>
                    <b-col md="4">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <label class="mr-1">Search</label>
                                <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                    class="d-inline-block" />
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>

            </div>

            <!-- table -->
            <vue-good-table id="printTable" :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :pagination-options="{
    enabled: false,
    perPage: pageLength
}">
                <template slot="table-row" slot-scope="props">

                    <!-- Column: Name -->
                    <span v-if="props.column.field === 'orderCreateDate'" class="text-nowrap">
                        <span class="text-nowrap">{{ convertTimeStampToDateLocal(props.row.orderCreateDate) }}</span>
                    </span>

                    <!-- Column: Name -->
                    <span v-else-if="props.column.field === 'dueDate'" class="text-nowrap">
                        <span class="text-nowrap">{{ convertTimeStampToDate(props.row.dueDate) }}</span>
                    </span>

                    <span v-else-if="props.column.field === 'customerName'" class="text-nowrap">
                        <span>{{ props.row.customerName+' ('+props.row.customerMobileNo+')' }}</span>
                    </span>

                    <span v-else-if="props.column.field === 'orderNo'">
                        <b-link v-b-toggle.sidebar-right-orderDetail @click="getOrderDetail(props.row.id)">
                            {{ '#'+props.row.orderNo }}
                        </b-link>
                    </span>

                    <!-- Column: Status -->
                    <span v-else-if="props.column.field === 'status'">
                        <b-badge :variant="statusVariant(props.row.status)">
                            {{ props.row.status }}
                        </b-badge>
                    </span>


                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item"
                                class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </b-card>

</template>
<script>
import {
    BCard, BForm, BRow, BCol, BFormInput, BFormGroup, BPagination, BButton, BFormSelect, BInputGroup, BInputGroupPrepend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import api from '@/store/api'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
        BCard, BForm, BRow, BCol, BFormInput, BFormGroup, BButton, BPagination, BFormSelect, BInputGroup, BInputGroupPrepend, flatPickr, vSelect, VueGoodTable
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Store Name',
                    field: 'storeName',
                    width: '150px'
                },
                {
                    label:'Customer Name',
                    field:'customerName',
                    width:'150px'
                },
                {
                    label: 'Order No',
                    field: 'orderNo',
                    width: '90px'
                },
                {
                    label: 'Order Date',
                    field: 'orderCreateDate',
                    width: '145px'
                },
                {
                    label: 'Due Date',
                    field: 'dueDate',
                    width: '145px'
                },
                {
                    label: 'Total Items',
                    field: 'totalItems',
                    width: '80px'
                },
                {
                    label: 'Total Pcs',
                    field: 'totalPices',
                    width: '80px'
                },
                {
                    label: 'Under Process',
                    field: 'itemUnderProcess',
                    width: '80px'
                },
                {
                    label: 'Ready',
                    field: 'itemReady',
                    width: '80px'
                },
                {
                    label: 'Delivered',
                    field: 'itemDelevered',
                    width: '80px'
                },
                // {
                //     label: 'Assigned / Out For Delivery',
                //     field: 'itemAssignedForDelivery',
                // },
                {
                    label: 'Total Amount',
                    field: 'totalNetAmount',
                    width: '80px'
                },
                {
                    label: 'Balance Amount',
                    field: 'totalDueAmount',
                    width: '80px'
                },
                {
                    label: 'Order Status',
                    field: 'orderStatus',
                    width: '80px'
                },

            ],
            rows: [],
            searchTerm: '',

            searchCritriaOption: [],
            searchCritria: { 'filterType': 'mobileNo', 'name': 'MOBILE_NO' },
            Selected: 'Search Customer',
            searchBoxValue: '',
            customersList: [],

            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },
            orderStatusOptions: [],
            orderStatus: 'COMPLETED',
            orderCreatedBy: '',
            orderCreatedByOptions: [],
            orderInspectedBy: '',
            orderInspectedByOptions: [],
            dueDate: null,
            fromDate: this.convertTimeStampToDateWithoutTimeYYY(new Date()),
            toDate: this.convertTimeStampToDateWithoutTimeYYY(new Date()),
            customerId: 0,
            customersList: []
        }
    },
    methods: {
        convertTimeStampToDateWithoutTime(value) {
            if (value) {
                return moment.utc(value).format('DD/MM/YYYY');
            } else {
                return '';
            }
        },
        convertTimeStampToDateWithoutTimeYYY(value) {
            if (value) {
                return moment.utc(value).format('YYYY-MM-DD');
            } else {
                return '';
            }
        },

        convertTimeStampToDate(value) {
            if (value) {
                return moment.utc(value).format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },

        convertTimeStampToDateLocal(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions.push({ 'id': 0, 'storeName': 'All' })
                    for (let index = 0; index < response.data.length; index++) {
                        self.storeNameOptions.push(response.data[index]);

                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getOrderStatusList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getOrdersStatus', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.orderStatusOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getEmployeesList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/getStoreEmployeesDetails/' + self.storeName.id + ',' + 0, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.orderCreatedByOptions = response.data;
                    self.orderInspectedByOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getOrderFilteredList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            if (self.fromDate == null && self.toDate == null) {
                data = JSON.stringify(
                    {
                        "customerId": self.customerId,
                        "orderStatus": self.orderStatus,
                        "storeId": self.storeName.id,
                    }
                );
            } else {
                data = JSON.stringify(
                    {
                        "customerId": self.customerId,
                        "orderStatus": self.orderStatus,
                        "storeId": self.storeName.id,
                        "fromDate": self.fromDate,
                        "toDate": self.toDate
                    }
                );
            }


            axios(api.getApi('put', '/orders/getOrdersFilteredList', data))
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.rows = response.data;
                    } else {
                        self.rows = [];
                        self.$swal({
                            title: '',
                            text: 'No Record Found!',
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-secondary',
                            },
                            buttonsStyling: false,
                        })
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });


        },
        printReport() {
            var divContents = document.getElementById("printTable").innerHTML;
            var printWindow = window.open('', '', '', '');
            printWindow.document.write('<html><head><title>' + this.storeName.storeName + ' - Order Report</title>');
            printWindow.document.write('<style>td{text-align:center;} footer:{text-align:center; position: fixed; bottom: 0;}</style>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(divContents);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        },
        onSelected(option) {
            this.Selected = option.fullName;
            this.searchBoxValue=option.mobileNo;
            this.customerId = option.id
        },
        onInputChange(text) {
            if (text === '' || text === undefined) {
                return
            }
            this.getCustomerList(text);
        },
        getCustomerList(text) {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/customers/filter/' + this.searchCritria.filterType + ',' + text, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.customersList = response.data;
                    } else {
                        self.customersList = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getSearchFiltersList() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getSearchFiltersList', data))
                .then(function (response) {
                    for (let index = 0; index < response.data.length; index++) {
                        if (response.data[index] == 'ALL') {
                            self.searchCritriaOption.push({ 'filterType': 'all', 'name': response.data[index] })
                        } else if (response.data[index] == 'NAME') {
                            self.searchCritriaOption.push({ 'filterType': 'name', 'name': response.data[index] })
                        } else if (response.data[index] == 'ADDRESS') {
                            self.searchCritriaOption.push({ 'filterType': 'address', 'name': response.data[index] })
                        } else if (response.data[index] == 'MOBILE_NO') {
                            self.searchCritriaOption.push({ 'filterType': 'mobileNo', 'name': response.data[index] })
                        } else if (response.data[index] == 'EMAIL_ID') {
                            self.searchCritriaOption.push({ 'filterType': 'emailId', 'name': response.data[index] })
                        } else if (response.data[index] == 'MEMBERSHIP_ID') {
                            self.searchCritriaOption.push({ 'filterType': 'membershipId', 'name': response.data[index] })
                        }

                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
    },
    created() {
        this.getStoreName();
        this.getOrderStatusList();
        this.getSearchFiltersList();
    }
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>